<template>
	<!-- v-if="state === 'pc'" -->
	<div v-if="state === 'pc'">
		<!-- 轮播图 -->
		<div class="cont">
			<el-carousel indicator-position="none" height="700px">
				<el-carousel-item v-for="(item, index) in img" :key="index">
					<img :src="item.src" class="img" />
					<div class="cont1" v-if="index == 0">
						<div style="font-size: 36px">学蝉培训机构智慧管理系统</div>
						<div style="font-size: 18px; margin-top: 20px">
							专注提升机构管理效率，助力机构全面发展
						</div>
						<div class="freebtn" @click="dialogFormVisible = true">
							<div style="line-height: 38px;cursor: pointer;">免费试用</div>
						</div>
					</div>
					<div class="cont1" v-if="index == 1">
						<div style="font-size: 36px">学蝉体测</div>
						<div style="font-size: 18px; margin-top: 20px" @click="dialogFormVisible = true">
							智能体测数据分析，提升机构的专业水平
						</div>
						<div class="freebtn">
							<div style="line-height: 38px; cursor: pointer;" @click="dialogFormVisible = true">
								免费试用
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<ApplyDialog v-model = "dialogFormVisible" />
		<!-- 产品功能 -->
		<div class="cont_1">
			<div style="text-align: center; font-size: 36px">
				为培训机构全面赋能
			</div>
			<div class="line"></div>
			<div class="cont1">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="el-img">
							<img src="../assets/img/pic_education.png" alt="" />
						</div>
					</el-col>
					<el-col :span="12">
						<div class="el-txt">
							<div style="font-size: 28px">学蝉管理系统</div>
							<div style="font-size: 18px; margin-top: 20px">
								培训机构管理系统，全面升级运营管理，提供一站式教学化解决方案
							</div>
							<div class="morebtn">
								<div style="line-height: 38px;cursor: pointer;" @click="ToOtherPage('Management')">了解更多</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="cont2">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="el-txt">
							<div style="font-size: 28px">学蝉体测</div>
							<div style="font-size: 18px; margin-top: 20px">
								体质/体态测试，为设计课程提供指引，促进学员健康成长，提升机构专业度
							</div>
							<div class="morebtn">
								<div style="line-height: 38px;cursor: pointer;" @click="ToOtherPage('Physical')">了解更多</div>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="el-img">
							<img src="../assets/img/pic_physicaltest.png" alt="" />
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 新闻动态 -->
		<div class="cont_2">
			<div class="title">贴心服务 助力运营</div>
			<div class="line"></div>
			<div class="cont1">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_education.png" alt="" />
							<div>专注教培</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_operate.png" alt="" />
							<div>操作便捷</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_serve.png" alt="" />
							<div>服务优质</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_aftersales.png" alt="" />
							<div>售后服务</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_safety.png" alt="" />
							<div>安全保障</div>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top: 60px;">
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_guide.png" alt="" />
							<div>3V1指导</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_online.png" alt="" />
							<div>7X14h在线</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_multichannel.png" alt="" />
							<div>多渠道响应</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_returnvisit.png" alt="" />
							<div>定期回访</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/icon_upgrade.png" alt="" />
							<div>免费升级</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 客户 -->
		<div class="cont_3">
			<div class="title">我们的客户</div>
			<div class="line"></div>

			<div class="cont1">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_1.png" alt="胜毅武道馆" width="76" height="76" />
								<!-- <div class="name">多乐艺术</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_2.png" alt="国际腾飞跆拳道" width="76" height="76" />
								<!-- <div class="name">国际腾飞跆拳道</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_3.png" alt="OP&KSL 儿童运动中心" width="76" height="76" />
								<!-- <div class="name">精英跆拳道</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_4.png" alt="胜毅武道馆" width="76" height="76" />
								<!-- <div class="name">胜毅武道馆</div> -->
							</div>
						</el-card>
					</el-col>
				</el-row>
				<el-row :gutter="20"
					style="margin-top: 40px;width: 80%;position: absolute;left: 50%;transform: translateX(-50%);">
					<el-col :span="8">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_5.png" alt="艺扬武道" width="76" height="76" />
								<!-- <div class="name">艺扬武道</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="8">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_6.png" alt="龙腾武道" width="76" height="76" />
								<!-- <div class="name">发景跆拳道</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="8">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_7.png" alt="風雲跆拳道" width="76" height="76" />
								<!-- <div class="name">弘道跆拳道</div> -->
							</div>
						</el-card>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top: 200px;">
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_8.png" alt="爱动篮球" width="76" height="76" />
								<!-- <div class="name">爱动篮球</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_9.png" alt="锐新体育" width="76" height="76" />
								<!-- <div class="name">锐新体育</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_10.png" alt="宇豪体育" width="76" height="76" />
								<!-- <div class="name">宇豪体育</div> -->
							</div>
						</el-card>
					</el-col>
					<el-col :span="6">
						<el-card shadow="always">
							<div class="customLogo">
								<img src="../assets/img/clientLogo/logo_11.png" alt="博仁跆拳道" width="76" height="76" />
								<!-- <div class="name">虓龍运动</div> -->
							</div>
						</el-card>
					</el-col>
				</el-row>
			</div>
		</div>
		<Contant/>
	</div>
	<div v-else class="mobile">
		<div class="bg">
			<h2 class="Htitle">
				学蝉教培管理系统
			</h2>
			<div class="bottoom">
				<img src="../assets/img/mobile/code.jpg" class="code" alt="">
				<p class="codeTip">扫码了解更多</p>
				<a class="ahref" href="tel:17318002756">17318002756</a>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		useStore
	} from "vuex";
	import {
		computed,
		ref
	} from "vue";
	import { useRouter } from 'vue-router';
	import ApplyDialog from "@/components/ApplyDialog.vue";
	import Contant from "@/components/Contant.vue";
	//FormInstance
	export default {
		name: "Home",
		components: {
			ApplyDialog,
			Contant
		},
		setup() {
			const {replace}  = useRouter();
			const state = computed(() => useStore().state.client);
			const img = ref([{
					src: require("../assets/img/bg-banner.png")
				},
				{
					src: require("../assets/img/physical_banner.png")
				},
			]);
			const dialogFormVisible = ref(false);
			
			function ToOtherPage(urlName){
				// router.push('/management')
				replace({ name:urlName });
			}

			return {
				state,
				img,
				dialogFormVisible,
				ToOtherPage
			};
		},
	};
</script>

<style lang="scss" scoped>
	.cont_1 {
		color: #281816;
		margin-top: 80px;
		position: relative;
		padding: 10px;

		.line {
			width: 141px;
			height: 2px;
			background-color: #ff7360;
			position: absolute;
			margin-top: 10px;
			left: 50%;
			transform: translateX(-50%);
		}

		.morebtn {
			margin-top: 30px;
			font-size: 16px;
			width: 160px;
			height: 38px;
			background-color: #fff;
			border: 1px solid rgb(255, 118, 94);
			border-radius: 20px;
			margin-right: 40px;
			color: #ff7360;
			text-align: center;
		}

		.cont1 {
			position: relative;
			margin-top: 80px;

			.el-txt {
				padding: 150px 80px;
			}

			.el-img {
				float: right;
				padding: 0 60px;
			}
		}

		.cont2 {
			position: relative;
			margin-top: 80px;

			.el-txt {
				float: right;
				padding: 150px 80px;
			}

			.el-img {
				padding: 0 60px;
			}
		}
	}

	.cont_2 {
		margin-top: 60px;
		height: 650px;
		background: #fef9f3;
		position: relative;

		.cont1 {
			font-size: 18px;
			color: #3a4259;
			width: 70%;
			margin-top: 120px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			.el-col-6 {
				max-width: 20% !important;
			}

			.content {
				text-align: center;
			}
		}
	}

	.title {
		padding-top: 40px;
		font-size: 36px;
		color: #281816;
		text-align: center;
	}

	.line {
		width: 140px;
		height: 2px;
		background-color: #ff7360;
		position: absolute;
		margin-top: 10px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cont_3 {
		margin-top: 40px;
		height: 650px;
		position: relative;

		.cont1 {
			font-size: 18px;
			color: #3a4259;
			width: 70%;
			margin-top: 100px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			.name {
				position: absolute;
				margin-left: 100px;
				top: 50%;
				transform: translateY(-50%);
			}
			.customLogo{
				display: flex; 
				align-items: center; 
				justify-content: center;
			}
		}
	}

	.compatible {
		color: #474747;
		font-size: 0.14rem;
		margin-bottom: 0.8rem;
	}

	.footer {
		color: #0bd561;
		font-size: 0.4rem;
	}
	
	.mobile{
		.bg{
			width: 100%;
			height: 100vh;
			background-image: url(../assets/img/mobile/bg.jpg);
			background-size: 100% 100%;
			position: relative;
			.Htitle{
				font-size: 0.58rem;
				color: #FFF;
				position: absolute;
				top:0.6rem;
				left: 0.32rem;
			}
			.bottoom{
				position: absolute;
				bottom: 0.6rem;
				width: 100%;
				text-align: center;
			}
			.code{
				width: 2.8rem;
				height: 2.8rem;
			}
			.codeTip{
				text-align: center;
				font-size: 0.3rem;
				line-height: 0.3rem;
			}
			.ahref{
				font-size: 0.28rem;
				line-height: 0.56rem;
				color: #000;
				display: block;
			}
		}
	}
</style>
