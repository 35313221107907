<template>
	<el-dialog v-model="dialogFormVisibleDialog" title="免费试用" center="true" @close='cancel'>
		<el-form :model="form" :rules="rules" ref="defalutForm">
			<el-form-item label="机构名称" label-width="140px" prop="name">
				<el-input v-model="form.name" autocomplete="off" />
			</el-form-item>
			<el-form-item label="联系人姓名" label-width="140px" prop="contacts">
				<el-input v-model="form.contacts" autocomplete="off" />
			</el-form-item>
			<el-form-item label="手机号码" label-width="140px" prop="phone">
				<el-input v-model="form.phone" autocomplete="off" />
			</el-form-item>
			<el-form-item label="验证码" label-width="140px" prop="msgCode" :rules="[
			{ required: true, message: '请输入验证码', trigger: 'blur' },
			]">
				<el-input class="msginput" v-model="form.msgCode" autocomplete="off" />
				<el-button style="margin-left: 16px; width: 112px;" type="danger" :disabled="checkCodeBtn.disabled" @click="sendMsg">
					{{checkCodeBtn.text}}</el-button>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cancel">取消</el-button>
				<el-button type="danger" @click="submit">提交</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		ref,
		reactive,
		watch
	} from "vue";
	import axios from "axios";
	import {
		rsaEncrypt
	} from "@/utils/rsa.js";
	import {
		ElMessage
	} from "element-plus";
	export default {
		props: {
			modelValue: {
				type: Boolean,
				default: false
			},
			phoneNumber:{
				type: String,
				default: ''
			}
		},
		emits: ['update:modelValue'],
		setup(props, {
			emit
		}) {
			const defalutForm = ref();
			let checkCodeBtn = reactive({
			      text: '获取验证码',
			      disabled: false,
			      duration: 120,
			      timer: null
			    })
			
			const dialogFormVisibleDialog = ref(props.modelValue);
			const form = reactive({
				name: "",
				phone: '',
				contacts: "",
				msgCode: "",
				fromInfo:1
			});
			watch(dialogFormVisibleDialog.value, (newVal) => {
				emit('update:modelValue', newVal)
			})
			watch(props, (newVal) => {
				dialogFormVisibleDialog.value = newVal.modelValue
				form.phone = newVal.phoneNumber
			})
			
			const rules = {
				name: [{
					required: true,
					message: "请输入机构名称",
					trigger: "blur"
				}],
				contacts: [{
					required: true,
					message: "请输入联系人姓名",
					trigger: "blur"
				}, ],
				phone: [{
						required: true,
						message: "请输入手机号码",
						trigger: "blur"
					},
					{
						pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
						message: "请输入正确的手机号码",
						trigger: "blur",
					},
				],
			};

			function cancel() {
				dialogFormVisibleDialog.value = false
				emit('update:modelValue', false)
			}
			async function sendMsg() {
				await defalutForm.value.validateField("phone", (valid) => {
					if (!valid) {
						sendPhoneMeg()
						// return new Promise((resolve, reject) => {
						// });
					} else {
						console.log("error submit!");
					}
				});
			}
			
			function sendPhoneMeg(){
				if(checkCodeBtn.duration !== 120){
					checkCodeBtn.disabled = true
				}
				// 清除掉定时器
				checkCodeBtn.timer && clearInterval(checkCodeBtn.timer)
				const msg = {
					mobile: form.phone,
					timestamp: Date.parse(new Date()) / 1000,
				};
				const encryptMsg = rsaEncrypt(JSON.stringify(msg));
				const objdata = {
					mobile:encryptMsg,
					msgType:2
				}
				axios.post(`https://api.ixuechan.com/auth/sendMsgCode`, objdata).then((res) => {
						const { code, msg }  = res.data;
						if (code == 200) {
							ElMessage.success("发送成功");
							checkCodeBtn.disabled = true
							// 开启定时器
							checkCodeBtn.timer = setInterval(() => {
								const tmp = checkCodeBtn.duration--
								checkCodeBtn.text = `${tmp}秒`
								if (tmp <= 0) {
								  // 清除掉定时器
								  clearInterval(checkCodeBtn.timer)
								  checkCodeBtn.duration = 120
								  checkCodeBtn.text = '获取验证码'
								  // 设置按钮可以单击
								  checkCodeBtn.disabled = false
								}
							}, 1000)
							
						} else {
							ElMessage.error(msg || "请过两分钟后重新发送");
							//ElMessage.error("请过两分钟后重新发送");
						}
					})
					.catch((err) => {
						console.log("失败：", err);
						// reject(err);
					});
			}
			
			async function submit() {
				await defalutForm.value.validate((valid) => {
					if (valid) {
						return new Promise((resolve, reject) => {
							axios
								.post(
									"https://api.ixuechan.com/system/institutionTrial/apply",
									form
								)
								.then((res) => {
									const { code, msg, data }  = res.data;
									
									if (code == 200) {
										resolve(data);
										ElMessage.success("申请成功，请等待审核");
										dialogFormVisibleDialog.value = false;
									} else {
										ElMessage.error(msg);
									}
								})
								.catch((err) => {
									console.log("失败：", err);

									reject(err);
								});
						});
					}
				});
			}

			return {
				dialogFormVisibleDialog,
				defalutForm,
				rules,
				form,
				sendMsg,
				submit,
				cancel,
				checkCodeBtn
			};
		},
	};
</script>

<style lang="scss" scoped>
	.msginput {
		width: 60%;
	}
</style>
