<template>
  <div :class="state">
    <div class="container">
      <!-- 左侧logo -->
      <a href="#">
        <div style="display: flex">
          <div>
            <img src="@/assets/log.png" alt="" width="52" height="52" />
          </div>
          <div
            style="
              color: #000000;
              font-size: 22px;
              margin-left: 10px;
              line-height: 50px;
            "
          >
            学蝉
          </div>
        </div>
      </a>

      <!-- 右侧导航 -->
      <el-menu
        v-if="state === 'pc'"
        :default-active="activeIndex"
        mode="horizontal"
        text-color="#464646"
        active-text-color="#898989"
        router
      >
        <el-menu-item index="home">首页</el-menu-item>
        <el-menu-item index="management">学蝉教培管理系统</el-menu-item>
        <el-menu-item index="physical">学蝉体测</el-menu-item>
      </el-menu>
      <div class="freebtn">
        <div
          style="line-height: 38px;cursor: pointer;"
          onclick="window.open('https://b.ixuechan.com/')"
        >
          机构登录
        </div>
      </div>
      <div class="loginbtn">
        <div style="line-height: 38px;cursor: pointer;" @click="dialogFormVisible = true">
          免费试用
        </div>
      </div>
    </div>
  </div>
  <ApplyDialog v-model = "dialogFormVisible" />
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ApplyDialog from "@/components/ApplyDialog.vue";
export default {
  components: {
	ApplyDialog
  },
  setup() {
    const state = computed(() => useStore().state.client);
    const activeIndex = ref("home");
    const route = useRoute();
    watch(
      () => route.path,
      () => {
        activeIndex.value !== route.path.substring(1)
          ? (activeIndex.value = route.path.substring(1))
          : null;
        console.log(activeIndex.value);
      }
    );
    const dialogFormVisible = ref(false);
    return {
      state,
      activeIndex,
      dialogFormVisible,
    };
  },
};
</script>

<style lang="scss">
.pc {
  .container {
    width: 12rem;
    height: 96px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    .el-menu {
      border: none;
      .el-menu-item {
        font-size: 0.18rem;
      }
      .is-active {
        border-bottom-color: #f90 !important;
        color: #f80 !important;
      }
    }
    a {
      width: 180px;
      height: 40px;
      font-size: 18px;
      margin-left: 40px;
    }
    .freebtn {
      font-size: 18px;
      width: 160px;
      height: 38px;
      background-color: #fff;
      border: 1px solid rgb(255, 118, 94);
      border-radius: 4px;
      margin-right: 40px;
      color: #ff7360;
      text-align: center;
      // transform: translateY(50%);
    }
    .loginbtn {
      width: 160px;
      height: 38px;
      font-size: 18px;
      background-color: #ff765b;
      color: #fff;
      border-radius: 4px;
      margin-right: 40px;
      text-align: center;
    }
  }
}
.mobile {
  .container {
    font-size: 0;
    box-sizing: border-box;
    padding: 0.2rem 0.1rem;
    a {
      img {
        width: 2.5rem;
      }
    }
  }
}
</style>
