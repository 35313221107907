<template>
	<div class="contant-box">
		<el-popover placement="left" :width="140" trigger="hover">
			<template #reference>
				<div class="contant-view">
					<img src="../assets/img/icon_wechat.png" alt="学蝉客服微信" >
				</div>
			</template>
			<div class="box-show">
				<div class="image-box"><img src="../assets/img/customer.jpg" alt="学蝉客服" class="image-kefu"></div>
				<div class="line">学蝉客服</div>
			</div>
		</el-popover>
		<el-popover placement="left" :width="140"  style="height: 120px;" trigger="hover">
			<template #reference>
				<div class="contant-view">
					<img src="../assets/img/icon_customerse.png" alt="学蝉客服电话" >
				</div>
			</template>
			<div class="box-show">
				<div class="title">咨询热线</div>
				<div class="phone">173-1800-2756</div>
			</div>
		</el-popover>
		
	</div>
</template>

<script>
	export default {

		setup() {
			return {

			};
		},
	};
</script>

<style lang="scss" scoped>
	.contant-box {
		position: fixed;
		right: 0;
		bottom: 40%;
		z-index: 100;
		.contant-view{
			width: 40px;
			height:40px;
			background: #FF5D3F;
			border-radius: 2px 0px 0px 2px;
			font-size: 14px;
			margin-bottom: 12px;
			text-align: center;
			position: relative;
			img{
				position: absolute;
				top:50%;
				left: 50%;
				width: 28px;
				height: 28px;
				margin-left: -14px;
				margin-top: -14px;
			}
		}	
	}
	.box-show{
		width: 140px;
		// height: 140px;
		text-align: center;
		// background-color: red;
		.image-box{
			width: 125px;
			height: 125px;
			overflow: hidden;
			margin: 0 auto;
			.image-kefu{
				width: 100%;
				height: 100%;
			}
		}
		.line{
			color: #252323;
			font-size: 12px;
			line-height: 26px;
			text-align: center;
		}
		.title{
			color: rgba(37, 35, 35, 0.72);
			margin-bottom: 8px;
			font-size: 12px;
			text-align: left;
		}
		.phone{
			color: #252323;
			font-weight: 500;
			font-size: 12px;
			text-align: left;
		}
	}
</style>
