import { JSEncrypt } from "jsencrypt";
//公钥
const PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjx0/xCyE3/V0cXZUn4AwhrCOgQnpdmKG+9kxkTbGG7/EFFgOkKG78BaqwIZGGmvuj5Mesu5/aYWPuSszbM6FKlyB/u2MQ3m0QYjdw9/EPEK3SmHiqNDP4endFR/XicIS5hbu/Vrvm4Z+gFi4KDun5J5gc80cA0zi+ki0BZIb3ePGtacUgrdOTmorI2GcohkR53OfbkhWJcrkOkn7yBSj1ygsJ5BVYQVZeELqoXzycFweI+R3/Srm0c6ebBCJ0W3WFxTML2aDO3BrTR5Wa2Ok870U+iJsUFilxw2A57xvLvojZes4gn9it5vl4cw/hU3KRQIzhFpxASfs+9AHLDzPEQIDAQAB-----END PUBLIC KEY-----
`;
//私钥
const PRIVATE_KEY = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCPHT / ELITf9XRxdlSfgDCGsI6BCel2Yob72TGRNsYbv8QUWA6QobvwFqrAhkYaa +
	6 Pkx6y7n9phY + 5 KzNszoUqXIH + 7 YxDebRBiN3D38Q8QrdKYeKo0M / h6d0VH9eJwhLmFu79Wu +
	bhn6AWLgoO6fknmBzzRwDTOL6SLQFkhvd48a1pxSCt05OaisjYZyiGRHnc59uSFYlyuQ6SfvIFKPXKCwnkFVhBVl4QuqhfPJwXB4j5Hf9KubRzp5sEInRbdYXFMwvZoM7cGtNHlZrY6TzvRT6ImxQWKXHDYDnvG8u +
	iNl6ziCf2K3m + XhzD + FTcpFAjOEWnEBJ + z70AcsPM8RAgMBAAECggEAP2FUvCx7LDQoPZeKIplC5GOMuNkK68L2xNKLgSQg2Gk1DU1yJdt6v /
	lJ5bmRkmH6ZNti3RuCkdj0razUOXu8veX6LExHshpUGjHjBLw0y / s /
	8 VEocFsJtaM8pf5UFk480GmXrdx4AQivPWgNJsREtprtXFuZ7FxAqVdJYq4yaTGpxesnKeqJFLEj0EsEUtSNkKg6GrnHsKQLAjgFzLd4QjDl5v / +
	mX6AGhtKIhs / dVwhtteQxBnkXVxQz0MpfqHhojS5zd0 / XSZsAOa9HBQ8vgG0tyuoQ0ZaDUb17nFK8c0oaIM7nhanv +
	SeIVbPyynW0wE5j8wNSUPCvSGFqVZHgQKBgQDrRhC + ifuKNcQr + 9 z3au0EVZctumo / ULREaQSGJcozGUr + 8 q8aGY32 / k /
	iMAiyHoYZGcv / EfAvyvMWJiU8iacfvgmksdgumpuPN6YA3CgAHT1Icv + 0o2 VLd7DYWtc3t / dsD7 /
	sgKPZv0IU3Q5YwBQsKRFgixFPVXy8TU2o8uQTEwKBgQCbuMmW / D8gZgkh276bWxSfclbYak6vb / CPHApmEYVi96TwB6r9hia3x +
	QJUBnq7xZzg4Wiut7ox6CzEqfogGhwUGD + QlzR / w06kp + Vmu83upH64St44nLz9nHMfU7WD8buLx22Z05b5MQ96gaSFAdcFzZtx0cud01gp /
	rdI + 51 ywKBgQC91CSCeDZ6h3MmwerBvoZKbvyxqq07NNSeNdWunyMMI6Y9GnZpqqC + 3 uZ5a6Q4gyRTcFsGDOUsyqVkA5cbxz0PAXgqaEwzHg /
	ReGb6Xqfvn6footDqYMPEJmiLyI / Jpyd9CyAhpJGPiR + 6 UbnXpfMgxmn86Xgn + KPSEtVMMd4P / QKBgC /
	Fc4pvHcqJotmRORsdyOjVQdEoY7UsSQcOGh6P13Sx9hZws8MMyYntt4Yn3ZuV9zQ + r9tpKoMM4r6sxXftFJ0H +
	4 lkwGbnsJ48ZtqVajeVc70cIKL4DT3MZ8LM2kePFX7yU1ImxQIp3w4DYjqkDFHzCQ6KaMrXbJRvU +
	VNZZUbAoGAS4q5mp31b7ue9wgqYnZxZjvLlCL9XcIULkdFVPBNCcDfpIBqJFTDICYeivIxc / wVMncEH /
	Rfb9pjWfb1Z5qBaVeiCwSgDs3OuVKLgfxXlGX5iCUxDSDMN9bkoNYlb3LFiPW2NJkLh + P0BOZj3aQLi5vvjEnbGHnwe4 + 6 t / CAt1Q =-----END PRIVATE KEY-----`;
//const enc = new JSEncrypt();
//设置公钥
//enc.setPublicKey(PUBLIC_KEY);
//设置私钥
//enc.setPublicKey(PRIVATE_KEY);
// 加密
export function rsaEncrypt(msg) {
  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(PUBLIC_KEY);
  const encryptMsg = jsencrypt.encrypt(msg);
  return encryptMsg;
}

export function rsaDecrypt(msg) {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(PRIVATE_KEY);
  const decryptMsg = decrypt.decrypt(msg);
  return decryptMsg;
}
