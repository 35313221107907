<template>
	<div class="cont_4">
		<div class="title">信息化管理 就用学蝉</div>
		<div class="line"></div>
		<div class="cont1">
			<el-input v-model="phone" placeholder="请输入手机号免费试用！" type="number"
				:input-style="{ width: '500px', height: '50px' }" clearable />
			<div class="freebtn">
				<div style="line-height: 48px;cursor: pointer;" @click="dialogFormVisible = true">免费试用</div>
			</div>
		</div>
		<ApplyDialog v-model="dialogFormVisible" :phoneNumber = 'phone' />
	</div>
	<div class="footer">
		<div style="
        padding-top: 100px;
        width: 70%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      ">
			<el-row justify="space-evenly">
				<el-col :span="8">
					<div>
						<div class="title">关于我们</div>
						<div class="content" style="display: flex">
							<div>
								<img src="@/assets/log.png" alt="" width="76" height="76" />
							</div>

							<div style="margin-left: 20px">
								<div>学蝉体测</div>
								<div style="margin-top: 20px">教培管理系统</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div>
						<div class="title">联系我们</div>
						<div class="content">
							<div>电话：17318002756</div>
							<div>邮箱：xuechantech@163.com</div>
							<div>
								地址：深圳市南山区招商街道沿山社区沿山路43号创业壹号大楼A栋107室
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div style="padding-left: 100px">
						<div class="title">关注我们</div>
						<div class="content">
							<img src="../assets/img/we.jpg" alt="" width="96" height="96" />
							<img src="../assets/img/wechat.jpg" alt="" width="96" height="96"
								style="margin-left: 34px" />
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<p class="caseFiex">
			<img src="../assets/img/police.png">
			<a class="banquan" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022015888号</a>
		</p>
	</div>
	
</template>

<script>
	import {
		ref
	} from "vue";
	import ApplyDialog from "@/components/ApplyDialog.vue";
	export default {
		components: {
			ApplyDialog
		},
		setup() {
			//const router = useRouter();
			const dialogFormVisible = ref(false);
			const phone = ref("");

			return {
				phone,
				dialogFormVisible
			};
		},
	};
</script>

<style lang="scss" scoped>
	.cont_4 {
		height: 600px;
		width: 100%;
		background: url("../assets/img/bg_bpttom.png") no-repeat;
		// background-size: cover;
		background-size: 100% 100%;
		/*宽高都100%*/
		background-position: center top;
		margin-top: 80px;
		position: relative;

		.cont1 {
			width: 500px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			.freebtn {
				margin-top: 10px;
				width: 500px;
				height: 50px;
				font-size: 18px;
				background-color: #ff7360;
				color: #fff;
				border-radius: 4px;
				margin-right: 40px;
				text-align: center;
			}
		}
	}

	.title {
		padding-top: 40px;
		font-size: 36px;
		color: #281816;
		text-align: center;
	}

	.line {
		width: 140px;
		height: 2px;
		background-color: #281816;
		position: absolute;
		margin-top: 10px;
		left: 50%;
		transform: translateX(-50%);
	}

	.footer {
		position: relative;
		height: 381px;
		background-color: rgba(10, 10, 36, 1);

		.el-col {
			//background-color: rgb(66, 66, 238);
		}

		.title {
			font-size: 18px;
			color: #fff;
			text-align: left;
		}

		.content {
			margin-top: 60px;
			font-size: 16px;
			color: #fff;
		}
	}
	.caseFiex{
		width: 100%;
		text-align: center;
		color: #FFF;
		font-size: 14px;
		position: absolute;
		bottom:20px;
		img{
			position: relative;
			top:4px;
		}
		.banquan{
			color: #FFF;
			font-size: 14rpx;
		}
	}
</style>
