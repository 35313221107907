<template>
  <div>
    <Header v-if="state === 'pc'"/>
    <router-view />
	<div v-if="state === 'pc'">
    <Footer v-if="state === 'pc'"/>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { onBeforeMount, ref } from "@vue/runtime-core";
export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const store = useStore();
    const state = ref("pc");
    const setMode = () => {
      let w = document.documentElement.clientWidth;
      if (w > 960) {
        state.value = "pc";
        store.state.client === "mobile" ? store.commit("setWidth", "pc") : null;
        return;
      }
      state.value = "mobile";
      store.state.client === "pc" ? store.commit("setWidth", "mobile") : null;
    };
    onBeforeMount(setMode);
    window.addEventListener("resize", setMode);
	//百度监听数据
	var _hmt = _hmt || [];
	(function() {
	  var hm = document.createElement("script");
	  hm.src = "https://hm.baidu.com/hm.js?71d340129bfde43968050c3e319d36a2";
	  var s = document.getElementsByTagName("script")[0]; 
	  s.parentNode.insertBefore(hm, s);
	})();
	
	
    return {
      state,
    };
  },
};
</script>

<style lang="scss">
	html{ font-size:100px;}
	.cont {
		height: 600px;
		width: 100%;
		position: relative;
	
		// background: url("../assets/img/bg-banner.png");
		//background-size: cover;
		//background-size: 100% 100%; /*宽高都100%*/
		// background-position: center top;
		.cont1 {
			color: #fff;
			position: absolute;
			top: 200px;
			left: 10%;
			z-index: 2;
	
			.freebtn {
				margin-top: 30px;
				font-size: 18px;
				width: 160px;
				height: 38px;
				background-color: #fff;
				border: 1px solid rgb(255, 118, 94);
				border-radius: 4px;
				margin-right: 40px;
				color: #ff7360;
				text-align: center;
			}
		}
	
		.img {
			width: 100%;
			height: 600px;
			// background-size: cover;
			// background-position: center top;
		}
	}
	
</style>
