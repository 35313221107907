import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },

  {
    path: "/management",
    name: "Management",
    component: () => import("../views/management.vue"),
  },
  {
    path: "/physical",
    name: "Physical",
    component: () => import("../views/physical.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior () {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return { left: 0, top: 0 }
  },
  routes,
});

export default router;
